.NavbarItems {
  /* background-color: #222; */
  background-color: #fff;
  box-shadow: 0 5px 15px 0 rgba(0, 0, 0, 0.25);
  width: 95%;
  height: 80px;
  border-radius: 13px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 30px;
  position: fixed;
  top: 20px;
  left: 50%;
  z-index: 9999;
  transform: translate(-50%);
}

.navbar-logo {
  color: #222;
  font-size: 2rem;
  justify-self: start;
  cursor: pointer;
}

.fa-react {
  margin-left: 0.2rem;
  font-size: 1.6rem;
}

.nav-menu {
  display: grid;
  grid-template-columns: repeat(6, auto);
  grid-gap: 10px;
  list-style: none;
  align-items: center;
  text-align: center;
  justify-content: end;
}

.nav-links {
  color: #222;
  text-decoration: none;
  padding: 0.7rem 1rem;
  font-size: 1.2rem;
  font-weight: 600;
  white-space: nowrap;
}

.nav-links i {
  padding-right: 10px;
}

.nav-links:hover {
  background-color: #0a3d55;
  color: #fff;
  border-radius: 4px;
  transition: all 0.2s ease-in-out;
}

button {
  padding: 0.5rem 1rem;
  white-space: nowrap;
  border-radius: 0.3rem;
  font-size: 1rem;
  font-weight: 600;
  border: none;
  cursor: pointer;
  transition: 0.2s ease-in-out;
}

button:hover {
  background-color: #151516;
  color: #fff;
}

.fa-bars {
  color: #222;
}

.nav-links-mobile {
  display: none;
}

.menu-icons {
  display: none;
}

@media screen and (max-width: 850px) {
  .NavbarItems {
    z-index: 99;
  }
  .nav-menu {
    /* background: rgba(255, 255, 255, 0.25);
    box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
    backdrop-filter: blur(4px);
    -webkit-backdrop-filter: blur(4px);
    
    border: 1px solid rgba(255, 255, 255, 0.18); */
    background-color: #fff;
    border-radius: 13px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 100%;
    height: auto;
    position: absolute;
    top: 0px;
    left: -110%;
    opacity: 1;
    transition: all 0.3s ease;
    align-items: stretch;
    padding: 80px 0 30px 0;
    margin: 0;
  }

  .nav-menu.active {
    left: 0;
    opacity: 1;
    transition: all 0.3s ease;
    z-index: -1;
  }

  .nav-links {
    display: block;
    width: 100%;
    font-size: 1.2rem;
    padding: 2rem 0;
  }

  .nav-links:hover {
    background: #0a3d55;
    transition: none;
  }

  .menu-icons {
    display: block;
    position: absolute;
    top: 25px;
    right: 30px;
    font-size: 1.5rem;
    cursor: pointer;
  }

  .nav-links-mobile {
    display: block;
    text-align: center;
    padding: 1.5rem;
    margin: auto;
    border-radius: 4px;
    width: 80%;
    background: #707e98;
    text-decoration: none;
    color: #fff;
    font-weight: 700;
    letter-spacing: 2px;
    font-size: 1.2rem;
  }
  .fa-times {
    color: #222;
  }

  button {
    display: none;
  }
}
