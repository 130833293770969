.video {
  margin: 4rem 6rem;
  color: #2a2a2a;
}

.video h1 {
  font-size: 3rem;
}

.videocard {
  margin-top: 3rem;
  display: flex;
  justify-content: space-between;
}

.t-card {
  width: 32%;
  text-align: start;
  box-shadow: 0 5px 25px 2px rgba(0, 0, 0, 0.11);
  border-radius: 7px;
  cursor: pointer;
  padding: 1rem 0.8rem;
}
.t-image {
  height: 300px;
  overflow: hidden;
  border-radius: 7px;
}

.t-image img {
  width: 100%;
  height: 100%;
  border-radius: 7px;
  transition: 0.3s ease-in-out;
}

.t-card h4 {
  font-size: 1.3rem;
  padding: 0 0 0.5rem 0.2rem;
}

@media screen and (max-width: 850px) {
  .video {
    margin: 4rem 2rem;
  }
  .videocard {
    margin-top: 3rem;
    display: flex;
    flex-direction: column;
  }
  .t-card {
    margin-bottom: 1.5rem;
    width: 100%;
  }
}

.container {
  position: relative;
  width: 100%;
  overflow: hidden;
  padding-top: 56.25%; /* 16:9 Aspect Ratio */
}

.responsive-iframe {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  border: none;
}