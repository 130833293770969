.typewriter {
  border-right: 2px solid;
  white-space: nowrap;
  overflow: hidden;
  animation: typing 4s steps(40, end), blink 0.75s step-end infinite;
}

@keyframes typing {
  from {
    width: 0;
  }
  to {
    width: 100%;
  }
}

@keyframes blink {
  50% {
    border-color: transparent;
  }
}
