.about-container {
  margin: 4rem 6rem;
  color: #2a2a2a;
  text-align: start;
}


.about-container p {
  padding-bottom: 2rem;
}

@media screen and (max-width: 850px) {
  .about-container {
    margin: 4rem 2rem;
  }
}

.centered-iframe {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.video-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px; /* Adjust margin as needed */
}

.heading {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 10vh;
}

.heading h1 {
  text-align: center;
}

